// src/pages/GalleryPage.js
import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import your actual images
import image1 from "../images/image1.JPG";
import image2 from "../images/image2.JPG";
import image3 from "../images/image3.JPG";
import image4 from "../images/image4.JPG";
import image5 from "../images/image5.JPG";
import image6 from "../images/image6.JPG";
import image7 from "../images/image7.JPG";
import image8 from "../images/image8.JPG";

const images = [
  { src: image1, caption: "Event 1: Science Fair" },
  { src: image2, caption: "Event 2: Sports Day" },
  { src: image3, caption: "Event 3: Art Exhibition" },
  { src: image4, caption: "Event 4: Graduation Ceremony" },
  { src: image5, caption: "Event 5: Music Festival" },
  { src: image6, caption: "Event 6: Field Trip" },
  { src: image7, caption: "Event 7: Community Service" },
  { src: image8, caption: "Event 8: Cultural Day" },
];

function GalleryPage() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Gallery Section */}
      <div className="py-20 px-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white text-center">
        <h1 className="text-5xl font-bold mb-6">Gallery</h1>
        <p className="text-lg mb-4">
          Welcome to the World Star Academy Gallery!
        </p>
        <p className="text-lg mb-8">
          Explore the vibrant life at our academy through photographs that
          capture our events, achievements, and the spirit of our community.
        </p>
      </div>

      {/* Image Grid */}
      <div className="py-16 px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {images.map((image, index) => (
            <div
              key={index}
              className="rounded-lg overflow-hidden shadow-lg cursor-pointer"
              onClick={() => handleImageClick(image)}
            >
              <img
                src={image.src}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-48 object-cover"
              />
              <p className="text-center mt-2 text-gray-800 font-semibold">
                {image.caption}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for Image Details */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-lg p-4 max-w-md w-full">
            <img
              src={selectedImage.src}
              alt="Selected"
              className="w-full h-auto mb-4"
            />
            <p className="text-center text-lg font-semibold mb-2">
              {selectedImage.caption}
            </p>
            <button
              onClick={closeModal}
              className="w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default GalleryPage;
