import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-600 to-indigo-700 text-white py-8">
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex flex-col md:flex-row md:justify-between">
          {/* About Section */}
          <div className="mb-6 md:mb-0">
            <h2 className="text-2xl font-bold mb-2">About Us</h2>
            <p className="text-gray-300">
              World Star Academy is dedicated to fostering a nurturing
              environment for students to thrive academically and personally.
              Join us in shaping the future of education.
            </p>
          </div>

          {/* Quick Links */}
          <div className="mb-6 md:mb-0">
            <h2 className="text-2xl font-bold mb-2">Quick Links</h2>
            <ul>
              <li>
                <a
                  href="/about"
                  className="text-gray-300 hover:text-white transition"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/programs"
                  className="text-gray-300 hover:text-white transition"
                >
                  Programs
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="text-gray-300 hover:text-white transition"
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href="/blog"
                  className="text-gray-300 hover:text-white transition"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div>
            <h2 className="text-2xl font-bold mb-2">Contact Us</h2>
            <p className="text-gray-300 mb-2">
              <strong>Email:</strong> info@worldstaracademy.com.ng
            </p>
            <p className="text-gray-300 mb-2">
              <strong>Phone:</strong> +1 (404) 916-5852
            </p>
            <p className="text-gray-300">
              <strong>Address:</strong> Sawmill, Ilorin, Kwara State
            </p>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center mt-6">
          <a
            href="https://web.facebook.com/profile.php?id=61563399890342"
            className="text-gray-300 hover:text-white mx-2 transition"
          >
            <FaFacebookF size={20} />
          </a>
          <a
            href="https://twitter.com"
            className="text-gray-300 hover:text-white mx-2 transition"
          >
            <FaTwitter size={20} />
          </a>
          <a
            href="https://instagram.com"
            className="text-gray-300 hover:text-white mx-2 transition"
          >
            <FaInstagram size={20} />
          </a>
          <a
            href="https://linkedin.com"
            className="text-gray-300 hover:text-white mx-2 transition"
          >
            <FaLinkedinIn size={20} />
          </a>
        </div>
      </div>

      <div className="bg-indigo-800 text-center py-4">
        <p className="text-gray-300">
          &copy; {new Date().getFullYear()} World Star Academy. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
