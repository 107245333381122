// src/pages/AdmissionPage.js
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AdmissionForm from "../components/AdmissionForm";

function AdmissionPage() {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Hero Section */}
      <div className="flex justify-center items-center py-20 px-4 bg-gradient-to-r from-blue-500 to-indigo-500 text-white">
        <div className="text-center max-w-2xl">
          <h1 className="text-5xl font-bold mb-6">
            Welcome to World Star Academy
          </h1>
          <p className="text-lg mb-8">
            "Education is the passport to the future, for tomorrow belongs to
            those who prepare for it today." Begin your journey of excellence
            with us by applying for admission. We're excited to welcome you to a
            nurturing and academically vibrant community. Join us, and let's
            build your future together!
          </p>
          <p className="text-lg font-semibold">
            Admissions for the 2024 academic year are now open!
          </p>
        </div>
      </div>

      {/* Admission Form Section */}
      <div className="flex justify-center py-16 px-4 flex-grow">
        <div className="bg-white rounded-lg shadow-2xl p-10 w-full max-w-4xl">
          <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">
            Apply for Admission
          </h2>
          <p className="text-gray-600 text-center mb-8">
            Fill out the form below to start your admission process at World
            Star Academy. We are committed to providing the best educational
            experience for your child.
          </p>

          {/* Admission Form */}
          <AdmissionForm />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default AdmissionPage;
