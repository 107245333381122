import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer"; // Importing Footer component
import {
  FaAward,
  FaHistory,
  FaLightbulb,
  FaStar,
  FaRegListAlt,
  FaQuoteRight,
} from "react-icons/fa";

const AboutPage = () => {
  return (
    <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 min-h-screen pb-10">
      <div className="bg-gray-900 bg-opacity-50 min-h-screen">
        <Navbar />
        <div className="max-w-7xl mx-auto mt-10 p-6">
          {/* Hero Section */}
          <h1
            className="text-4xl font-bold text-white text-center"
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <span className="text-indigo-400 block">Welcome to</span>
            <span
              className="text-yellow-300 block"
              style={{
                position: "relative",
                zIndex: 10,
                display: "inline-block",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-2px)";
                e.currentTarget.style.textShadow =
                  "2px 2px 5px rgba(0, 0, 0, 0.5)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.textShadow = "none";
              }}
            >
              World
              <span
                style={{
                  position: "absolute",
                  left: 1,
                  top: 1,
                  color: "red",
                }}
              >
                World
              </span>
            </span>
            <span
              className="text-green-400 block"
              style={{
                position: "relative",
                zIndex: 10,
                display: "inline-block",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-2px)";
                e.currentTarget.style.textShadow =
                  "2px 2px 5px rgba(0, 0, 0, 0.5)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.textShadow = "none";
              }}
            >
              Star
              <span
                style={{
                  position: "absolute",
                  left: 1,
                  top: 1,
                  color: "red",
                }}
              >
                Star
              </span>
            </span>
            <span
              className="text-red-400 block"
              style={{
                position: "relative",
                zIndex: 10,
                display: "inline-block",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-2px)";
                e.currentTarget.style.textShadow =
                  "2px 2px 5px rgba(0, 0, 0, 0.5)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.textShadow = "none";
              }}
            >
              Academy
              <span
                style={{
                  position: "absolute",
                  left: 1,
                  top: 1,
                  color: "yellow",
                }}
              >
                Academy
              </span>
            </span>
          </h1>

          {/* Card Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12 mt-10">
            {/* Mission Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-indigo-100">
              <div className="flex items-center mb-4">
                <FaStar className="text-indigo-600 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Our Mission
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                Our mission is to foster a nurturing and challenging environment
                that promotes academic excellence, personal growth, and social
                responsibility. We are committed to helping each student reach
                their full potential.
              </p>
            </div>

            {/* Vision Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-yellow-100">
              <div className="flex items-center mb-4">
                <FaLightbulb className="text-yellow-500 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Our Vision
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                We envision a world where our students become leaders in their
                chosen fields, empowered with the knowledge, skills, and values
                necessary to make a positive impact on society.
              </p>
            </div>

            {/* What We Offer Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-yellow-200">
              <div className="flex items-center mb-4">
                <FaRegListAlt className="text-yellow-600 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  What We Offer
                </h2>
              </div>
              <ul className="text-gray-700 list-disc list-inside leading-relaxed space-y-2">
                <li>
                  <span className="font-semibold">
                    Comprehensive Curriculum:
                  </span>{" "}
                  A balanced and rigorous academic program that challenges and
                  engages students.
                </li>
                <li>
                  <span className="font-semibold">
                    Extracurricular Activities:
                  </span>{" "}
                  A variety of clubs, sports, and arts programs that allow
                  students to explore their interests and talents.
                </li>
                <li>
                  <span className="font-semibold">Supportive Community:</span> A
                  caring and inclusive environment where every student feels
                  valued and supported.
                </li>
              </ul>
            </div>
          </div>

          {/* Testimonials Section */}
          <div className="bg-white shadow-lg rounded-lg p-8 mb-12">
            <div className="flex items-center mb-4">
              <FaQuoteRight className="text-indigo-600 text-3xl mr-3" />
              <h2 className="text-2xl font-semibold text-gray-800">
                What Our Community Says
              </h2>
            </div>
            <p className="text-gray-700 leading-relaxed italic mb-4">
              "World Star Academy has been an incredible place for my child's
              growth and development. The teachers are supportive, and the
              environment is enriching."
            </p>
            <p className="text-gray-700 leading-relaxed italic">
              "The diverse curriculum and extracurricular activities offered at
              World Star Academy have truly helped my son become a well-rounded
              individual."
            </p>
          </div>

          {/* Proprietor Section */}
          <h2 className="text-4xl font-bold text-white text-center mt-12">
            Meet Our Proprietor
          </h2>
          <div className="flex justify-center mt-6">
            <img
              src="/path-to-proprietor-image.jpg" // Add your image path here
              alt="Proprietor"
              className="w-48 h-48 rounded-full shadow-lg"
            />
          </div>
          <div className="text-center text-white mt-4">
            <h3 className="text-2xl font-semibold">[M/S/M/A/Adedokun]</h3>
            <p className="text-lg mt-2">
              i am so much commited in helping students grows .]
            </p>
          </div>

          {/* Message from the Proprietor */}
          <div className="bg-white shadow-lg rounded-lg p-8 mt-10">
            <div className="flex items-center mb-4">
              <FaQuoteRight className="text-indigo-600 text-3xl mr-3" />
              <h2 className="text-2xl font-semibold text-gray-800">
                A Message from the Proprietor
              </h2>
            </div>
            <p className="text-gray-700 leading-relaxed mb-4">
              "Welcome to World Star Academy! As the proprietor of this esteemed
              institution, I am deeply committed to fostering an environment
              where students can thrive both academically and personally. Our
              vision is to cultivate not only knowledgeable learners but also
              compassionate leaders who will make a positive impact on the
              world.
            </p>
            <p className="text-gray-700 leading-relaxed mb-4">
              At World Star Academy, we believe that education is a partnership
              between students, teachers, and parents. We strive to provide a
              comprehensive curriculum that encourages critical thinking,
              creativity, and collaboration. Our dedicated faculty is here to
              inspire and support each student on their unique journey.
            </p>
            <p className="text-gray-700 leading-relaxed">
              Together, let us embark on this incredible journey of growth and
              discovery. Thank you for choosing World Star Academy as your
              partner in education!"
            </p>
          </div>

          {/* Contact Information Section */}
          {/* <div className="bg-gray-800 text-white rounded-lg p-8">
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="leading-relaxed mb-2">
              <strong>Email:</strong> info@worldstaracademy.edu
            </p>
            <p className="leading-relaxed mb-2">
              <strong>Phone:</strong> (123) 456-7890
            </p>
            <p className="leading-relaxed">
              <strong>Address:</strong> 1234 Academy Lane, Education City, EC
              56789
            </p>
          </div> */}
        </div>
      </div>

      {/* Footer Component */}
      <Footer />
    </div>
  );
};

export default AboutPage;
